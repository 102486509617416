<template>
  <div class="pdfViewer" v-if="ready">
    <div class="page" v-for="page in pages" :key="'page-'+ page" :id="'page' + page">
      <div class="canvasWrapper"><canvas :id="'pdfpage'+ page"></canvas></div>
      <div class="textLayer" :id="'textpage' + page"></div>
    </div>
  </div>
</template>

<script>
import async from 'async'
const pdfjs = require('pdfjs-dist/legacy/build/pdf.js')

// eslint-disable-next-line
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].js!pdfjs-dist/legacy/build/pdf.worker.js")

if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker()
}

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

let pdf

const render = async function (pageNumber) {
  try {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1.5 })
    const canvas = document.getElementById('pdfpage' + pageNumber)
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContex = {
      canvasContext: context,
      viewport: viewport
    }
    await page.render(renderContex)
    const textContent = await page.getTextContent()
    const textLayer = document.getElementById('textpage' + pageNumber)
    textLayer.style.left = canvas.offsetLeft + 'px'
    textLayer.style.top = canvas.offsetTop + 'px'
    textLayer.style.height = canvas.offsetHeight + 'px'
    textLayer.style.width = canvas.offsetWidth + 'px'
    await pdfjs.renderTextLayer({
      textContent: textContent,
      container: textLayer,
      viewport: viewport,
      textDivs: []
    }).promise
  } catch (e) {
    console.log(e)
  }
}

export default {
  name: 'PdfView',
  props: ['regulation'],
  created: async function () {
    try {
      let link = this.regulation.id
      if (this.regulation.uuid) {
        link = link + '-' + this.regulation.uuid
      }
      const pdfTask = pdfjs.getDocument(`https://d3j40fadoyw6ni.cloudfront.net/pdf/${link}.pdf`)
      pdf = await pdfTask.promise
      const pages = pdf.numPages
      for (let i = 1; i <= pages; i++) {
        this.pages.push(i)
      }
      this.ready = true
      await delay(100)
      await async.eachSeries(this.pages, async.asyncify(render))
      this.$emit('render-completed')
    } catch (e) {
      this.$logger.warn(e)
    }
  },
  data: function () {
    return {
      pages: [],
      ready: false
    }
  }
}
</script>

<style>
.pdfViewer {
  padding-bottom: 5px;
}

.pdfViewer .canvasWrapper {
  overflow: hidden;
}

.pdfViewer .page {
  direction: ltr;
  margin: 10px;
  position: relative;
  overflow: visible;
  border: 10px solid;
  background-clip: content-box;
  border-image: url('/img/shadow.png') 9 9 repeat;
  background-color: rgba(255, 255, 255, 1);
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}

.textLayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.2;
  line-height: 1;
}

.textLayer span,
.textLayer br {
  color: transparent;
  position: absolute;
  white-space: pre;
  cursor: text;
  transform-origin: 0% 0%;
}

.textLayer .highlight {
  margin: -1px;
  padding: 1px;
  background-color: rgba(180, 0, 170, 1);
  border-radius: 4px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: rgba(0, 100, 0, 1);
}

.textLayer ::-moz-selection {
  background: rgba(0, 0, 255, 1);
}

.textLayer ::selection {
  background: rgba(0, 0, 255, 1);
}

.textLayer .endOfContent {
  display: block;
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  bottom: 0;
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.textLayer .endOfContent.active {
  top: 0;
}

</style>
