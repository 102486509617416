<template>
  <div class="my-4">
    <b-container fluid>
      <b-row class="mb-4">
        <b-col md="12" lg="6">
          <strong>Activies daily (all - not impacted by filters)</strong>
          <b-card>
            <div id="activitesChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
        <b-col md="12" lg="6">
          <strong>Activies by user (all - not impacted by filters)</strong>
          <b-card>
            <div id="usersChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <strong>Created and updated records by last date</strong>
          <b-card>
            <div id="dailyChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col lg="12" xl="6">
          <b-card>
            <div id="typesChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
        <b-col lg="12" xl="6">
          <b-card>
            <div id="statusChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="entitiesChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="areasChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <b-card>
            <div id="requirementsChart" style="width: 100%; height: 400px;"></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
const themeContrast = { color: ['#00338D', '#EAAA00', '#005EB8', '#43B02A', '#0091DA', '#009A44', '#483698', '#470A68', '#6D2077', '#00A3A1'] }
echarts.registerTheme('kpmg', theme)
echarts.registerTheme('contrast', themeContrast)

export default {
  name: 'AppObligationsDashboard',
  props: ['annotations', 'regulation', 'showMiddle'],
  computed: {
  },
  created: async function () {
    this.$stat.log({ page: 'RegulaltionDasboard', action: 'open RegulationDashboard' })
    this.load()
  },
  data () {
    return {}
  },
  methods: {
    load: async function () {
      const dashboard = await this.$Amplify.API.get('cosmos', `/obligations/regulation/${this.regulation.id}/dashboard`)
      let types = []
      _.each(_.countBy(this.annotations, x => x.obligationtype?.name), (value, key) => {
        types.push({ name: key, value: value, label: { position: 'left' } })
      })
      types = _.sortBy(types, ['value'])
      let status = []
      _.each(_.countBy(this.annotations, x => x.statusname), (value, key) => {
        status.push({ name: key, value: value, label: { position: 'left' } })
      })
      let areasAll = []
      let createdAll = []
      let entitiesAll = []
      let requirementsAll = []
      let updatedAll = []
      _.each(this.annotations, annotation => {
        createdAll.push(moment(annotation.created_at).format('YYYY-MM-DD'))
        updatedAll.push(moment(annotation.updated_at).format('YYYY-MM-DD'))
        _.each(annotation.areas, area => {
          areasAll.push(area.name)
        })
        _.each(annotation.entities, entity => {
          entitiesAll.push(entity.name)
        })
        _.each(annotation.requirements, x => {
          requirementsAll.push(x.name)
        })
      })
      let areas = []
      _.each(_.countBy(areasAll, x => x), (value, key) => {
        areas.push({ name: key, value: value, label: { position: 'left' } })
      })
      let created = []
      _.each(_.countBy(createdAll, x => x), (value, key) => {
        created.push({ name: key, value: value, label: { position: 'left' } })
      })
      let entities = []
      _.each(_.countBy(entitiesAll, x => x), (value, key) => {
        entities.push({ name: key, value: value, label: { position: 'left' } })
      })
      let requirements = []
      _.each(_.countBy(requirementsAll, x => x), (value, key) => {
        requirements.push({ name: key, value: value, label: { position: 'left' } })
      })
      let updated = []
      _.each(_.countBy(updatedAll, x => x), (value, key) => {
        updated.push({ name: key, value: value, label: { position: 'left' } })
      })
      const typesOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '30%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: true,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: types
        },
        series: [
          {
            name: 'obligations',
            type: 'bar',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: types
          }
        ]
      }
      const statusOptions = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        series: [
          {
            name: 'Status',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['50%', '50%'],
            data: status,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      const entitiesOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '30%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: true,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: entities
        },
        series: [
          {
            name: 'entities',
            type: 'bar',
            stack: 'obligations',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: entities
          }
        ]
      }
      const requirementsOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '30%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: true,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: requirements
        },
        series: [
          {
            name: 'requirements',
            type: 'bar',
            stack: 'obligations',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: requirements
          }
        ]
      }
      const areasOptions = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        grid: {
          top: 0,
          bottom: 0,
          left: '30%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: true,
          splitLine: { lineStyle: { type: 'dashed' } }
        },
        yAxis: {
          type: 'category',
          axisLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          data: areas
        },
        series: [
          {
            name: 'functions',
            type: 'bar',
            stack: 'obligations',
            label: {
              show: true,
              formatter: '{b}'
            },
            data: areas
          }
        ]
      }
      const dailyOptions = {
        dataset: [
          {
            id: 'created',
            source: created
          },
          {
            id: 'updated',
            source: updated
          }
        ],
        legend: {
          data: ['created', 'updated']
        },
        xAxis: {
          type: 'time'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'created',
            datasetId: 'created',
            type: 'bar',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          },
          {
            name: 'updated',
            datasetId: 'updated',
            type: 'bar',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          }
        ]
      }
      const activitesOptions = {
        dataset: {
          source: dashboard.dailyActivity
        },
        xAxis: {
          type: 'time'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'activities',
            type: 'line',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          }
        ]
      }
      const usersOptions = {
        dataset: {
          source: dashboard.users
        },
        series: [
          {
            name: 'editors',
            type: 'pie',
            radius: ['30%', '60%'],
            center: ['40%', '50%'],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            encode: {
              value: 'count',
              tooltop: 'username',
              itemName: 'username'
            }
          }
        ]
      }
      echarts.init(document.getElementById('activitesChart'), 'kpmg').setOption(activitesOptions)
      echarts.init(document.getElementById('typesChart'), 'kpmg').setOption(typesOptions)
      echarts.init(document.getElementById('statusChart'), 'kpmg').setOption(statusOptions)
      echarts.init(document.getElementById('entitiesChart'), 'kpmg').setOption(entitiesOptions)
      echarts.init(document.getElementById('requirementsChart'), 'kpmg').setOption(requirementsOptions)
      echarts.init(document.getElementById('areasChart'), 'kpmg').setOption(areasOptions)
      echarts.init(document.getElementById('dailyChart'), 'contrast').setOption(dailyOptions)
      echarts.init(document.getElementById('usersChart'), 'kpmg').setOption(usersOptions)
    },
    reload: function () {
      echarts.dispose(document.getElementById('activitesChart'))
      echarts.dispose(document.getElementById('typesChart'))
      echarts.dispose(document.getElementById('statusChart'))
      echarts.dispose(document.getElementById('entitiesChart'))
      echarts.dispose(document.getElementById('requirementsChart'))
      echarts.dispose(document.getElementById('areasChart'))
      echarts.dispose(document.getElementById('dailyChart'))
      echarts.dispose(document.getElementById('usersChart'))
      this.load()
    },
    moment: function (value) {
      return moment(value)
    }
  },
  watch: {
    annotations: function () {
      this.reload()
    },
    showMiddle: function () {
      this.reload()
    }
  }
}
</script>

<style></style>
