<template>
  <div>
    <div v-if="level === 0">
      <div class="mb-2"><strong class="mr-2">Processes</strong></div>
      <div class="mb-2">
        <span v-if="!loading">
          <div v-for="value in values" :key="value.id">
            <router-link :to="{ name: 'Process', params: { id: value.id }}">
              {{value.path}}
            </router-link>
            <b-button variant="outline-primary" size="sm" v-on:click="deleteEdge(value)">remove</b-button>
          </div>
        </span>
        <span v-if="loading">
          <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
        </span>
      </div>
    </div>
    <div>
      <span v-if="!busy">
        <div v-if="selected">
          {{selected.number}} - {{selected.name}}
        </div>
        <div v-if="!selected">
          <b-form-select class="mb-2" v-model="selected" :options="options" />
        </div>
      </span>
      <span v-if="busy"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </div>
    <div v-if="selected">
      <select-process
        :existingEdges="edgesToPass"
        :level="level + 1"
        :process_parent_id="selected.id"
        :linkingModel="linkingModel"
        :parentId="parentId"
        :parentLinkingField="parentLinkingField"
        :relation="relation"
        v-on:saveEdgeUp="saveEdge"
      />
      <b-button v-if="noChildren" class="mb-2" variant="primary" size="sm" v-on:click="saveEdge(selected)">save</b-button>
      <b-button v-if="level === 0" class="mb-2" variant="outline-primary" size="sm" v-on:click="selected = null">canel</b-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

const getNumber = function (process) {
  if (process.parent === null) {
    return process.number
  } else {
    const result = getNumber(process.parent) + '.' + process.number
    return result
  }
}

const getPath = function (process) {
  if (process.parent === null) {
    return getNumber(process) + ' - ' + process.name
  } else {
    const result = getPath(process.parent) + ' > ' + getNumber(process) + ' - ' + process.name
    return result
  }
}

export default {
  name: 'SelectProcess',
  props: {
    existingEdges: Array,
    level: Number,
    linkable: {
      type: Boolean,
      default: false
    },
    linkingModel: String,
    linkName: {
      type: String,
      default: ''
    },
    parentId: Number,
    parentLinkingField: String,
    process_parent_id: Number,
    propsBased: {
      type: Boolean,
      defualt: false
    },
    relation: String,
    valuesProp: {
      type: Array,
      default: () => []
    },
    verticesProp: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    noChildren: function () {
      let result = false
      if (this.selected) {
        if (this.selected.children.length === 0) {
          result = true
        }
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.selected
    }
  },
  created: async function () {
    if (this.level === 0) {
      if (this.propsBased) {
        this.values = this.valuesProp
        this.loading = false
      } else {
        await this.loadEdges()
      }
      this.edgesToPass = this.values
    } else {
      this.edgesToPass = this.existingEdges
    }
    await this.buildOptions()
  },
  data () {
    return {
      busy: false,
      edit: false,
      edgesToPass: [],
      loading: true,
      options: [],
      selected: '',
      values: [],
      vertices: []
    }
  },
  methods: {
    buildOptions: async function () {
      this.$logger.debug('buildOptions start')
      this.busy = true
      try {
        let vertices = []
        if (this.level === 0 && this.verticesProp.length > 0) {
          vertices = this.verticesProp
        } else {
          // let response = await this.$http(config)
          let apiName = 'cosmos'
          let path = `/processes/parent/${this.process_parent_id}`
          vertices = await this.$Amplify.API.get(apiName, path)
          vertices.forEach(x => {
            x.numberLong = getNumber(x)
            x.path = getPath(x)
          })
        }
        this.vertices = vertices
        const optionsTemp = this.vertices.map(x => {
          return { id: x.id, value: x, text: x.number + ' - ' + x.name }
        })
        if (this.existingEdges.length > 0) {
          this.existingEdges.forEach(x => {
            _.remove(optionsTemp, _.matchesProperty('id', x.id))
          })
        }
        this.options = _.concat([{ value: null, text: 'select' }], _.sortBy(optionsTemp, ['text']))
        this.selected = null
        this.busy = false
      } catch (e) {
        this.$logger.warn('getting vertices error error: ' + e)
      }
    },
    deleteEdge: async function (value) {
      this.$logger.debug('deletePropertyList started')
      this.busy = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/relationship/${this.linkingModel}`
        let params = {
          body: {
            left: { column: this.parentLinkingField, id: this.parentId },
            right: { column: 'process_id', id: value.id }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$stat.log({ action: 'unlinked property', model: this.linkingModel, payload: params.body })
        this.$logger.debug('deleted OK:', response)
        let temp = this.values
        _.pull(temp, value)
        this.values = temp
        this.buildOptions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.busy = false
    },
    loadEdges: async function () {
      this.$logger.debug('loadEdges started')
      this.busy = true
      let labelDelField = 'no-del'
      let path = `/standard/relation/process/${this.parentId}/${this.relation}/${this.parentLinkingField}/${labelDelField}`
      try {
        let response = await this.$Amplify.API.get('cosmos', path)
        response.forEach(x => {
          x.numberLong = getNumber(x)
          x.path = getPath(x)
        })
        response = _.sortBy(response, ['numberLong'])
        this.values = response
        this.$logger.debug('load values', this.values)
        this.loading = false
      } catch (e) {
        this.$logger.warn('/topic/:id error', e)
      }
    },
    saveEdge: async function (selected) {
      if (this.level === 0) {
        this.$logger.debug(`saveEdge start`)
        this.busy = true
        try {
          // let response = await this.$http(config)
          const apiName = 'cosmos'
          const path = `/standard/relationship/${this.linkingModel}`
          const params = {
            body: {
              left: { column: this.parentLinkingField, id: this.parentId },
              right: { column: 'process_id', id: selected.id },
              username: this.user.username
            }
          }
          const response = await this.$Amplify.API.put(apiName, path, params)
          this.$stat.log({ action: 'linked new property', model: this.linkingModel, model_id: response.id, payload: response })
          this.$logger.debug('saved OK:', response)
          const newRow = {}
          newRow.id = selected.id
          newRow.path = selected.path
          this.values.push(newRow)
          this.selected = null
          await this.buildOptions()
        } catch (e) {
          this.$logger.warn('event saving error: ' + e)
        }
        this.busy = false
      } else {
        this.$emit('saveEdgeUp', selected)
      }
    }
  }
}
</script>

<style>
</style>
