import $ from 'jquery'
import Util from './util'

const extend = function (child, parent) {
  for (const key in parent) {
    if (hasProp.call(parent, key)) child[key] = parent[key]
  }

  function Ctor () {
    this.constructor = child
  }

  Ctor.prototype = parent.prototype
  child.prototype = new Ctor()
  child.__super__ = parent.prototype
  return child
}

const hasProp = {}.hasOwnProperty

const Range = {}

Range.sniff = function (r) {
  if (r.commonAncestorContainer != null) {
    return new Range.BrowserRange(r)
  } else if (typeof r.start === 'string') {
    return new Range.SerializedRange(r)
  } else if (r.start && typeof r.start === 'object') {
    return new Range.NormalizedRange(r)
  } else {
    console.error('Could not sniff range type')
    return false
  }
}

Range.nodeFromXPath = function (xpath, root) {
  var customResolver, evaluateXPath, namespace, node, segment
  if (root == null) {
    root = document
  }
  evaluateXPath = function (xp, nsResolver) {
    var exception
    if (nsResolver == null) {
      nsResolver = null
    }
    try {
      return document.evaluate('.' + xp, root, nsResolver, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
    } catch (error) {
      exception = error
      console.log('XPath evaluation failed.')
      console.log('Trying fallback...')
      return Util.nodeFromXPath(xp, root)
    }
  }
  if (!$.isXMLDoc(document.documentElement)) {
    return evaluateXPath(xpath)
  } else {
    customResolver = document.createNSResolver(document.ownerDocument === null ? document.documentElement : document.ownerDocument.documentElement)
    node = evaluateXPath(xpath, customResolver)
    if (!node) {
      xpath = ((function() {
        var i, len, ref, results
        ref = xpath.split('/')
        results = []
        for (i = 0, len = ref.length; i < len; i++) {
          segment = ref[i]
          if (segment && segment.indexOf(':') === -1) {
            results.push(segment.replace(/^([a-z]+)/, 'xhtml:$1'))
          } else {
            results.push(segment)
          }
        }
        return results
      })()).join('/')
      namespace = document.lookupNamespaceURI(null)
      customResolver = function(ns) {
        if (ns === 'xhtml') {
          return namespace
        } else {
          return document.documentElement.getAttribute('xmlns:' + ns)
        }
      }
      node = evaluateXPath(xpath, customResolver)
    }
    return node
  }
}

Range.RangeError = (function(superClass) {
  extend(RangeError, superClass)

  function RangeError(type, message, parent1) {
    this.type = type
    this.message = message
    this.parent = parent1 != null ? parent1 : null
    RangeError.__super__.constructor.call(this, this.message)
  }

  return RangeError
})(Error)

Range.BrowserRange = (function () {
  function BrowserRange(obj) {
    this.commonAncestorContainer = obj.commonAncestorContainer
    this.startContainer = obj.startContainer
    this.startOffset = obj.startOffset
    this.endContainer = obj.endContainer
    this.endOffset = obj.endOffset
  }

  BrowserRange.prototype.normalize = function (root) {
    var n, node, nr, r
    if (this.tainted) {
      console.error("You may only call normalize() once on a BrowserRange!")
      return false
    } else {
      this.tainted = true
    }
    r = {}
    if (this.startContainer.nodeType === Node.ELEMENT_NODE) {
      if (this.startOffset < this.startContainer.childNodes.length) {
        r.start = Util.getFirstTextNodeNotBefore(this.startContainer.childNodes[this.startOffset])
      } else {
        r.start = Util.getFirstTextNodeNotBefore(this.startContainer)
      }
      r.startOffset = 0
    } else {
      r.start = this.startContainer
      r.startOffset = this.startOffset
    }
    if (this.endContainer.nodeType === Node.ELEMENT_NODE) {
      node = this.endContainer.childNodes[this.endOffset]
      if (node != null) {
        n = node;
        while ((n != null) && (n.nodeType !== Node.TEXT_NODE)) {
          n = n.firstChild
        }
        if (n != null) {
          r.end = n
          r.endOffset = 0
        }
      }
      if (r.end == null) {
        if (this.endOffset) {
          node = this.endContainer.childNodes[this.endOffset - 1]
        } else {
          node = this.endContainer.previousSibling
        }
        r.end = Util.getLastTextNodeUpTo(node)
        r.endOffset = r.end.nodeValue.length
      }
    } else {
      r.end = this.endContainer
      r.endOffset = this.endOffset
    }
    nr = {}
    if (r.startOffset > 0) {
      if (!r.start.nextSibling || r.start.nodeValue.length > r.startOffset) {
        nr.start = r.start.splitText(r.startOffset)
      } else {
        nr.start = r.start.nextSibling
      }
    } else {
      nr.start = r.start
    }
    if (r.start === r.end) {
      if (nr.start.nodeValue.length > (r.endOffset - r.startOffset)) {
        nr.start.splitText(r.endOffset - r.startOffset)
      }
      nr.end = nr.start
    } else {
      if (r.end.nodeValue.length > r.endOffset) {
        r.end.splitText(r.endOffset)
      }
      nr.end = r.end
    }
    nr.commonAncestor = this.commonAncestorContainer;
    while (nr.commonAncestor.nodeType !== Node.ELEMENT_NODE) {
      nr.commonAncestor = nr.commonAncestor.parentNode;
    }
    return new Range.NormalizedRange(nr)
  }

  BrowserRange.prototype.serialize = function(root, ignoreSelector) {
    return this.normalize(root).serialize(root, ignoreSelector)
  }

  return BrowserRange
})()

Range.NormalizedRange = (function () {
  function NormalizedRange(obj) {
    this.commonAncestor = obj.commonAncestor
    this.start = obj.start
    this.end = obj.end
  }

  NormalizedRange.prototype.normalize = function (root) {
    return this
  }

  NormalizedRange.prototype.limit = function (bounds) {
    var i, len, nodes, parent, ref, startParents
    nodes = $.grep(this.textNodes(), function(node) {
      return node.parentNode === bounds || $.contains(bounds, node.parentNode);
    });
    if (!nodes.length) {
      return null
    }
    this.start = nodes[0]
    this.end = nodes[nodes.length - 1]
    startParents = $(this.start).parents()
    ref = $(this.end).parents()
    for (i = 0, len = ref.length; i < len; i++) {
      parent = ref[i]
      if (startParents.index(parent) !== -1) {
        this.commonAncestor = parent
        break
      }
    }
    return this
  }

  NormalizedRange.prototype.serialize = function (root, ignoreSelector) {
    var end, serialization, start
    serialization = function (node, isEnd) {
      var i, len, n, nodes, offset, origParent, textNodes, xpath;
      if (ignoreSelector) {
        origParent = $(node).parents(":not(" + ignoreSelector + ")").eq(0)
      } else {
        origParent = $(node).parent();
      }
      xpath = Util.xpathFromNode(origParent, root)[0]
      textNodes = Util.getTextNodes(origParent)
      nodes = textNodes.slice(0, textNodes.index(node))
      offset = 0
      for (i = 0, len = nodes.length; i < len; i++) {
        n = nodes[i]
        offset += n.nodeValue.length
      }
      if (isEnd) {
        return [xpath, offset + node.nodeValue.length]
      } else {
        return [xpath, offset]
      }
    };
    start = serialization(this.start)
    end = serialization(this.end, true)
    return new Range.SerializedRange({
      start: start[0],
      end: end[0],
      startOffset: start[1],
      endOffset: end[1]
    })
  }

  NormalizedRange.prototype.text = function () {
    var node
    return ((function() {
      var i, len, ref, results
      ref = this.textNodes()
      results = []
      for (i = 0, len = ref.length; i < len; i++) {
        node = ref[i]
        results.push(node.nodeValue)
      }
      return results
    }).call(this)).join('')
  }

  NormalizedRange.prototype.textNodes = function () {
    var end, ref, start, textNodes
    textNodes = Util.getTextNodes($(this.commonAncestor))
    ref = [textNodes.index(this.start), textNodes.index(this.end)], start = ref[0], end = ref[1]
    return $.makeArray(textNodes.slice(start, +end + 1 || 9e9))
  }

  NormalizedRange.prototype.toRange = function () {
    var range
    range = document.createRange()
    range.setStartBefore(this.start)
    range.setEndAfter(this.end)
    return range
  }

  return NormalizedRange
})()

Range.SerializedRange = (function () {
  function SerializedRange(obj) {
    this.start = obj.start
    this.startOffset = obj.startOffset
    this.end = obj.end
    this.endOffset = obj.endOffset
  }

  SerializedRange.prototype.normalize = function (root) {
    var contains, e, i, j, len, len1, length, node, p, range, ref, ref1, targetOffset, tn
    range = {}
    ref = ['start', 'end']
    for (i = 0, len = ref.length; i < len; i++) {
      p = ref[i]
      try {
        node = Range.nodeFromXPath(this[p], root)
      } catch (error) {
        e = error
        throw new Range.RangeError(p, ("Error while finding " + p + " node: " + this[p] + ": ") + e, e)
      }
      if (!node) {
        throw new Range.RangeError(p, "Couldn't find " + p + " node: " + this[p])
      }
      length = 0
      targetOffset = this[p + 'Offset']
      if (p === 'end') {
        targetOffset--
      }
      ref1 = Util.getTextNodes($(node))
      for (j = 0, len1 = ref1.length; j < len1; j++) {
        tn = ref1[j]
        if (length + tn.nodeValue.length > targetOffset) {
          range[p + 'Container'] = tn
          range[p + 'Offset'] = this[p + 'Offset'] - length
          break
        } else {
          length += tn.nodeValue.length
        }
      }
      if (range[p + 'Offset'] == null) {
        throw new Range.RangeError(p + "offset", "Couldn't find offset " + this[p + 'Offset'] + " in element " + this[p])
      }
    }
    contains = document.compareDocumentPosition == null ? function (a, b) {
      return a.contains(b)
    } : function(a, b) {
      return a.compareDocumentPosition(b) & 16
    }
    $(range.startContainer).parents().each(function () {
      if (contains(this, range.endContainer)) {
        range.commonAncestorContainer = this
        return false
      }
    })
    return new Range.BrowserRange(range).normalize(root)
  }

  SerializedRange.prototype.serialize = function (root, ignoreSelector) {
    return this.normalize(root).serialize(root, ignoreSelector)
  }

  SerializedRange.prototype.toObject = function () {
    return {
      start: this.start,
      startOffset: this.startOffset,
      end: this.end,
      endOffset: this.endOffset
    }
  }

  return SerializedRange
})()

Range.getSelectedRange = function (document) {
  const selection = document.getSelection()
  if (!selection.rangeCount || selection.getRangeAt(0).collapsed) {
    return null
  } else {
    return selection.getRangeAt(0)
  }
}

Range.pathFromNode = function (node, root = null) {
  if (node === undefined) {
    throw new Error('missing required parameter "node"')
  }

  root = root || document

  let path = '/'
  while (node !== root) {
    if (!node) {
      const message = 'The supplied node is not contained by the root node.'
      const name = 'InvalidNodeTypeError'
      throw new DOMException(message, name)
    }
    path = `/${nodeName(node)}[${nodePosition(node)}]${path}`
    node = node.parentNode
  }
  return path.replace(/\/$/, '')
}

// Get the XPath node name..
function nodeName (node) {
  switch (node.nodeName) {
    case '#text':
      return 'text()'
    case '#comment':
      return 'comment()'
    case '#cdata-section':
      return 'cdata-section()'
    default:
      return node.nodeName.toLowerCase()
  }
}

// Get the ordinal position of this node among its siblings of the same name.
function nodePosition (node) {
  const name = node.nodeName
  let position = 1
  while ((node = node.previousSibling)) {
    if (node.nodeName === name) position += 1
  }
  return position
}

export default Range
