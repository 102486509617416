<template>
<div>
  <div class="my-5 mx-5" v-if="!permission.read">
    <h1>You do not have access to this regulation</h1>
  </div>
  <div class="mb-4" v-if="permission.read">
    <b-overlay :show="loading" rounded="sm">
      <b-container fluid>
        <b-row class="mb-4" v-if="regulation">
          <b-col>
            <div><h1 style="font-size: 1.5em; font-weight: 700">{{regulation.name_short}}</h1></div>
            <div>{{regulation.name_long}}</div>
          </b-col>
        </b-row>
        <b-row class="mb-4" v-if="regulation">
          <b-col v-show="showRegulation">
            <div v-if="!regulation.pdf">
              <div v-html="regulation.body" id="regulationContainer" class="regulation-sidebar"></div>
            </div>
            <div v-if="regulation.pdf">
              <div id="regulationContainer" class="regulation-sidebar">
                <pdf-view :regulation="regulation" v-on:render-completed="onRenderCompleted" />
              </div>
            </div>
          </b-col>
          <b-col v-show="showMiddle">
            <div class="annotations-sidebar">
              <b-navbar>
                <b-nav-form inline class="mb-2">
                  <b-button class="mr-2" variant="outline-primary" v-show="!showDash" @click="showRegulation = !showRegulation">
                    <b-icon-arrow-bar-left v-if="showRegulation"></b-icon-arrow-bar-left>
                    <b-icon-arrow-bar-right v-if="!showRegulation"></b-icon-arrow-bar-right>
                  </b-button>
                  <b-form-input class="mr-2" v-model="searchFilter" placeholder="Search......" />
                  <b-button class="mr-2" variant="outline-primary" @click="download">download</b-button>
                  <b-button class="mr-2" variant="outline-primary" @click="logsShow">journal</b-button>
                  <b-button class="mr-2" variant="outline-primary" @click="removeDeleted">remove deleted</b-button>
                  <b-form-checkbox v-show="showRegulation" v-model="highlightingOn" name="check-button" switch>
                    Highlighting
                  </b-form-checkbox>
                </b-nav-form>
                <b-navbar-nav class="ml-auto">
                  <b-nav-form inline class="mb-2">
                    <b-button class="mr-2" v-if="!showRegulation" variant="outline-primary" @click="showDash = !showDash">
                      <b-icon-arrow-bar-left v-if="!showDash && !showRegulation"></b-icon-arrow-bar-left>
                      <b-icon-arrow-bar-right v-if="showDash"></b-icon-arrow-bar-right>
                    </b-button>
                  </b-nav-form>
                </b-navbar-nav>
              </b-navbar>
              <b-nav>
                <b-form inline class="mb-4">
                  <strong class="mr-2">showing {{annotationsShow.length}} of total {{annotations.length}} | </strong>
                  sorting:
                  <b-form-select class="mr-2" v-model="sortSelected" :options="sortOptions"></b-form-select>
                  filtering:
                  <b-form-select class="mr-2" v-model="workflowStatusSelected" :options="workflowStatusOptions"></b-form-select>
                  <b-form-select class="mr-2" v-model="typeFilterSelected" :options="typeFilterOptions"></b-form-select>
                </b-form>
              </b-nav>
              <div v-if="regulation.active.length > 0">
                <div v-for="row in regulation.active" :key="'active'+row.id">
                  This regulation <strong>{{row.relationship.name}}</strong> {{row.regulation.name_short}} |
                  <b-link :to="{ name: 'RegDecompWorkspace', params: { id: row.regulation.id }}" class="mr-2">go to workspace</b-link> |
                  <b-link class="mr-2" @click="importObligations(row.regulation.id, row)">show obligations</b-link>
                </div>
              </div>
              <div v-if="regulation.passive.length > 0">
                <div v-for="row in regulation.passive" :key="'passive'+row.id">
                  This regulation <strong>{{row.relationship.passive}}</strong> {{row.regulation.name_short}} |
                  <b-link :to="{ name: 'RegDecompWorkspace', params: { id: row.regulation.id }}" class="mr-2">go to workspace</b-link> |
                  <b-link class="mr-2" @click="importObligations(row.regulation.id, row)">show obligations</b-link>
                </div>
              </div>
              <div class="mt-4" v-if="warning">
                <b-alert show variant="warning"><strong>{{warning}}</strong></b-alert>
              </div>
              <div class="mt-2" v-if="showReload">
                <b-link @click="firstLoad" class="mr-2">go back - load obligations from {{regulation.name_short}}</b-link>
              </div>
              <div class="mb-3" v-for="annotation in annotationsShow" :key="'annotation-' + annotation.id">
                <div v-on:mouseenter="annotationFocus(annotation.id)" v-on:mouseleave="annotationFocusCancel">
                  <annotation-item
                    :allowImport="allowImport"
                    :annotation="annotation"
                    :areas="areas"
                    :editAlwaysOn="editAlwaysOn"
                    :entities="entities"
                    :processes="processes"
                    :regulation="regulation"
                    :regulationGraphNode="regulationGraphNode"
                    :requirements="requirements"
                    :riskcomponents="riskcomponents"
                    :showText="annotationShowText"
                    v-on:edit="annotationEditStart"
                  ></annotation-item>
                </div>
              </div>
            </div>
          </b-col>
          <b-col v-if="showDash">
              <b-navbar>
                <b-nav-form inline class="mb-2">
                  <b-button class="mr-2" variant="outline-primary" v-show="showDash" @click="showMiddle = !showMiddle">
                    <b-icon-arrow-bar-left v-if="showDash"></b-icon-arrow-bar-left>
                    <b-icon-arrow-bar-right v-if="!showDash"></b-icon-arrow-bar-right>
                  </b-button>
                </b-nav-form>
              </b-navbar>
            <regulation-dashboard
              :annotations="annotationsShow"
              :regulation="regulation"
              :showMiddle="showMiddle"
            ></regulation-dashboard>
          </b-col>
        </b-row>
      </b-container>
      <template #overlay>
        <div class="text-center">
          <h3>... {{message}} ...</h3>
        </div>
      </template>
    </b-overlay>

    <b-button pill class="fab" v-show="!logsModal && showRegulation" @click="annotationCreate">create</b-button>

    <b-sidebar id="sidebar-right" title="Create obligation" right shadow width="50%" v-model="obligationSidebar" @hidden="annotationCancel">
      <div class="px-3 py-2">
        <h5>Regulatory quote</h5>
        <div class="mb-2">
          {{form.text}}
        </div>
        <div class="mb-4">
        <b-button v-if="!reselectStarted && showRegulation" variant="outline-primary" @click="annotationReselect">reapply selected text</b-button>
        </div>
        <h5>Type <b-button variant="outline-primary" size="sm" v-b-modal.modal-type><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-type" title="Type help" ok-only>
          <p class="my-4">Optional field - but it is strongly encouraged to always select a type</p>
          <p class="my-4">Drop down select list with various types of annotations.</p>
          <p class="my-4">Obligation is the core type. Other types are supporting broader decomposition.</p>
        </b-modal>
        <b-form-select id="input-type" v-model="form.type_id" :options="typeOptions" class="mb-4"></b-form-select>
        <h5>Citation <b-button variant="outline-primary" size="sm" v-b-modal.modal-citation><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-citation" title="Citation help" ok-only>
          <p class="my-4">Mandatory field</p>
          <p class="my-4">Ciation is supposed to indicate a place in the document where the source of the annotation is.</p>
          <p class="my-4">It is a free text field so it is important to follow an agreed and consistent convention:</p>
          <p class="my-4">Article 3, paragraph 1 becomes 3.1</p>
          <p class="my-4">Article 3, paragraph 4, point c becomes 3.4.c</p>
          <p class="my-4">For other more complex documents consult and agree an approach with the SME leading decomposition of that document.</p>
        </b-modal>
        <b-form-input v-model="form.citation" placeholder="Citation"></b-form-input>
        <div class="mb-4"><small><b-icon-exclamation-circle-fill variant="danger" v-if="!formCitationValid"></b-icon-exclamation-circle-fill> minimum one character</small></div>
        <h5>Topic (optional) <b-button variant="outline-primary" size="sm" v-b-modal.modal-topic><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-topic" title="Topic help" ok-only>
          <p class="my-4">Optional field</p>
          <p class="my-4">Free text field to represent a high level topic or theme</p>
          <p class="my-4">It is not always obvious what the themes are so expect to revise and align topics at the end of the decomposition excercises
            when more content is created.
          </p>
          <p class="my-4">Don't capture anything if a topic is not obvious or helpful. For example, for definitions we would not usually expect to have a topic</p>
        </b-modal>
        <b-form-input v-model="form.topic" placeholder="Topic" class="mb-4"></b-form-input>
        <h5>Name <b-button variant="outline-primary" size="sm" v-b-modal.modal-name><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-name" title="Name help" ok-only>
          <p class="my-4">Mandatory field</p>
          <p class="my-4">Free text field to represent a name / title of the annotation.</p>
          <p class="my-4">Try to make it short, punchy and meaningful in the context of the regulation.</p>
          <p class="my-4">Think what you would like to see as a headline if you were searching for this obligation in a large database.</p>
        </b-modal>
        <b-form-textarea v-model="form.name" placeholder="Name" rows="2"></b-form-textarea>
        <div><b-alert v-if="duplicateName" show variant="danger">This obligation name already exists</b-alert></div>
        <div class="mb-4"><small><b-icon-exclamation-circle-fill variant="danger" v-if="!formNameValid"></b-icon-exclamation-circle-fill> minimum six characters</small></div>
        <h5>Description <b-button variant="outline-primary" size="sm" v-b-modal.modal-description><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-description" title="Decription help" ok-only>
          <p class="my-4">Mandatory field</p>
          <p class="my-4">Free text field to capture plain language obligation (or other items).</p>
          <p class="my-4">For obligations use active tense and try to follow a general pattern: "who must do what"</p>
        </b-modal>
        <b-form-textarea v-model="form.description" placeholder="Description" rows="5"></b-form-textarea>
        <div class="mb-4"><small><b-icon-exclamation-circle-fill variant="danger" v-if="!formDescriptionValid"></b-icon-exclamation-circle-fill> minimum six characters</small></div>
        <h5>Context (optional) <b-button variant="outline-primary" size="sm" v-b-modal.modal-context><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-context" title="Context help" ok-only>
          <p class="my-4">Optional field</p>
          <p class="my-4">Free text field to capture any additional context which does not constitute the core obligation.</p>
          <p class="my-4">It could be anything and teams working on specific regulations can agree more detailed and specific approach to using this field.</p>
        </b-modal>
        <b-form-textarea class="mb-4" v-model="form.context" placeholder="Context" rows="5"></b-form-textarea>
        <h5>Minimum standard (optional) <b-button variant="outline-primary" size="sm" v-b-modal.modal-standard><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-standard" title="Minimum standard help" ok-only>
          <p class="my-4">Optional field</p>
          <p class="my-4">Free text field to capture KPMG view of a required minimum standard around practical implementation of the obligation.</p>
          <p class="my-4">It can be used to capture minimum standard used in our gap analysis reports.</p>
        </b-modal>
        <b-form-textarea class="mb-4" v-model="form.standard" placeholder="Minimum expected standard" rows="5"></b-form-textarea>
        <h5>Lagging market practice (optional) <b-button variant="outline-primary" size="sm" v-b-modal.modal-lagging><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-lagging" title="Lagging market practice help" ok-only>
          <p class="my-4">Optional field</p>
          <p class="my-4">Free text field to capture KPMG view of a laggnig market practice in relation to how firms comply the obligation.</p>
          <p class="my-4">It can be used to capture examples of lagging practice used in our gap analysis reports.</p>
        </b-modal>
        <b-form-textarea class="mb-4" v-model="form.lagging" placeholder="Lagging market practice for comparison / benchmarking" rows="5"></b-form-textarea>
        <h5>Leading market practice (optional) <b-button variant="outline-primary" size="sm" v-b-modal.modal-leading><b-icon-question-circle /></b-button></h5>
        <b-modal id="modal-leading" title="Leading market practice help" ok-only>
          <p class="my-4">Optional field</p>
          <p class="my-4">Free text field to capture KPMG view of leading market practice in relation to how firms comply with the obligation.</p>
          <p class="my-4">It can be used to capture examples of leading practice used in our gap analysis reports.</p>
        </b-modal>
        <b-form-textarea class="mb-4" v-model="form.market_practice" placeholder="Leading market practice for comparison / benchmarking" rows="5"></b-form-textarea>
        <h5>Effective date (optional)</h5>
        <b-form-datepicker v-model="form.date_effective" class="mb-4"></b-form-datepicker>
        <div>
          <b-button class="mr-2" @click="annotationSave" v-if="annotationFormValid && this.statusCreating" variant="outline-primary">save</b-button>
          <b-button class="mr-2" @click="annotationEditSave" v-if="annotationFormValid && this.statusEditing" variant="outline-primary">save</b-button>
          <b-button @click="annotationCancel" variant="outline-primary">cancel</b-button>
        </div>
      </div>
    </b-sidebar>
    <b-modal v-model="logsModal" size="xl" scrollable ok-only title="Last 500 activities">
      <div v-if="logs.length > 0">
        <div class="mb-4" v-for="log in logs" :key="'log-' + log.id">
          <div><strong><span v-if="log.obligation.obligationtype">{{log.obligation.obligationtype.name}} -</span> {{log.obligation.id}} - {{log.obligation.name}}</strong></div>
          <div>{{log.username}} {{log.action}} at {{moment(log.created_at).format('HH:mm')}} on {{moment(log.created_at).format('YYYY-MM-DD')}}</div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import axios from 'axios'
import ac from '@/libs/accesscontrol'
import highlighter from '@/libs/highlighter'
import moment from 'moment'
import xpathRange from '@/libs/range'
import zipcelx from 'zipcelx'
import { BIconArrowBarLeft, BIconArrowBarRight, BIconExclamationCircleFill, BIconQuestionCircle } from 'bootstrap-vue'
import AnnotationItem from '@/components/Annotation.vue'
import PdfView from '@/components/PdfViewBase.vue'
import RegulationDashboard from '@/views/decomp/RegulationDashboard.vue'
import workflow from '@/workflows/annotation'

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

const getNumber = function (process) {
  if (process.parent === null) {
    return process.number
  } else {
    const result = getNumber(process.parent) + '.' + process.number
    return result
  }
}

const getPath = function (process) {
  if (process.parent === null) {
    return getNumber(process) + ' - ' + process.name
  } else {
    const result = getPath(process.parent) + ' > ' + getNumber(process) + ' - ' + process.name
    return result
  }
}

export default {
  name: 'RegDecompWorkspace',
  components: {
    AnnotationItem,
    BIconArrowBarLeft,
    BIconArrowBarRight,
    BIconExclamationCircleFill,
    BIconQuestionCircle,
    PdfView,
    RegulationDashboard
  },
  computed: {
    annotationFormValid: function () {
      return this.formCitationValid && this.formNameValid && this.formDescriptionValid && !this.duplicateName
    },
    annotationsShow: function () {
      let annotations = []
      if (this.ready) {
        annotations = _.sortBy(this.annotations, [this.sortSelected])
      } else {
        return []
      }
      if (this.workflowStatusSelected) {
        annotations = _.filter(annotations, ['status_id', this.workflowStatusSelected])
      }
      if (this.typeFilterSelected) {
        annotations = _.filter(annotations, ['type_id', this.typeFilterSelected])
      }
      if (this.searchFilter.length > 2) {
        annotations = annotations.filter(annotation => {
          const term = annotation.name + ' ' + annotation.context + ' ' + annotation.description + ' ' + annotation.citation + ' ' + annotation.text
          return term.toUpperCase().match(this.searchFilter.toUpperCase())
        })
      }
      annotations = _.map(annotations, annotation => {
        annotation.statusname = workflow.find(x => x.id === annotation.status_id).name
        return annotation
      })
      return annotations
    },
    duplicateName: function () {
      const matchedName = _.find(this.annotations, x => {
        return x.name === this.form.name && x.id !== this.annotation.id
      })
      if (matchedName) {
        return true
      } else {
        return false
      }
    },
    formCitationValid: function () {
      return this.form.citation.length > 0
    },
    formDescriptionValid: function () {
      return this.form.description.length > 5
    },
    formNameValid: function () {
      return this.form.name.length > 5
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$store.commit('setBrand', 'Obligations')
    this.firstLoad()
  },
  data () {
    return {
      allowImport: false,
      areas: [],
      annotation: null,
      annotationFocused: null,
      annotations: [],
      annotationShowText: false,
      client_communication: false,
      editAlwaysOn: true,
      entities: [],
      external_reporting: false,
      form: null,
      highlightingOn: true,
      internal_reporting: false,
      loading: true,
      message: '',
      moment: moment,
      obligationSidebar: false,
      logs: [],
      logsModal: false,
      permission: {
        read: false
      },
      processes: [],
      ready: false,
      recordkeeping: false,
      requirements: [],
      riskcomponents: [],
      regulation: null,
      regulationGraphNode: null,
      reselectStarted: false,
      searchFilter: '',
      showDash: false,
      showMiddle: true,
      showRegulation: true,
      showReload: false,
      sortSelected: 'scrollY',
      sortOptions: [
        { value: 'scrollY', text: 'position' },
        { value: 'name', text: 'name' }
      ],
      statusCreating: false,
      statusEditing: false,
      typeOptions: [],
      typeFilterOptions: [{ text: 'type - all', value: null }],
      typeFilterSelected: null,
      warning: null,
      workflowStatusOptions: [{ text: 'status - all', value: null }],
      workflowStatusSelected: null
    }
  },
  methods: {
    firstLoad: async function () {
      this.$stat.log({ page: 'workspace', action: 'open workspace' })
      this.message = 'Starting loading'
      this.resetImport()
      this.regulation = null
      this.annotations = []
      this.message = 'Checking permissions'
      this.permission.read = ac.can(this.user.acgroups).readAny('obligation').granted
      this.message = 'Resetting forms'
      this.annotationReset()
      this.annotationFormReset()
      let authorized = false
      try {
        this.message = 'Fetching regulation from database'
        this.regulation = await this.$Amplify.API.get('cosmos', `/regulation/${this.$route.params.id}`)
        document.title = this.regulation.name_short
        if (this.regulation.users.length > 0) {
          if (_.find(this.regulation.users, ['username', this.user.username])) {
            authorized = true
          }
        } else {
          authorized = true
        }
        if (authorized) {
          this.message = 'Fetching obligations from database'
          const db = await this.$Amplify.API.get('cosmos', `/obligations/workspace/regulation/${this.$route.params.id}`)
          const annotations = db.obligations
          annotations.forEach(annotation => {
            if (annotation.processes.length > 0) {
              annotation.processes.forEach(x => {
                x.numberLong = getNumber(x)
                x.path = getPath(x)
              })
              annotation.processes = _.sortBy(annotation.processes, ['numberLong'])
            }
          })
          this.annotations = annotations
          this.entities = db.entities
          this.areas = db.areas
          this.requirements = db.requirements
          this.riskcomponents = db.riskcomponents
          const processes = db.processes
          processes.forEach(x => {
            x.numberLong = getNumber(x)
            x.path = getPath(x)
          })
          this.processes = processes
          const types = db.types
          const typeOptions = []
          typeOptions.push({ value: null, text: 'Select type' })
          types.forEach(type => {
            typeOptions.push({ value: type.id, text: type.name })
            this.typeFilterOptions.push({ value: type.id, text: type.name })
          })
          this.typeOptions = typeOptions
          _.each(workflow, x => {
            this.workflowStatusOptions.push({ text: x.name, value: x.id })
          })
          if (this.regulation.static) {
            this.message = 'Fetching static file with regulation text'
            const httpRespone = await axios.get(`https://aws-website-regstore-d8uto.s3.amazonaws.com/html/${this.regulation.id}.html`)
            this.regulation.body = httpRespone.data
            this.message = 'Rendering regulation'
            await delay(2000)
          }
          if (!this.regulation.pdf) {
            this.message = 'Highlighting annotations'
            this.onRenderCompleted()
          }
        } else {
          this.message = 'No permission'
          this.permission.read = false
        }
      } catch (e) {
        this.message = 'Error ' + e
        this.$logger.warn('getting reulation error', e)
      }
    },
    annotationCancel: function () {
      this.annotationFormReset()
      this.annotationReset()
      this.obligationSidebar = false
      this.statusCreating = false
      this.statusEditing = false
    },
    annotationCreate: function () {
      let annotation = {}
      let selectedRange = xpathRange.getSelectedRange(document)
      // check if the selection is not empty
      if (selectedRange == null) {
        return
      }
      // check if the selection is in the regulation container range
      annotation.regulationContainerPath = xpathRange.pathFromNode(document.getElementById('regulationContainer'))
      let range = xpathRange.sniff(selectedRange)
      let normedRange = range.normalize(document)
      let serializedRange = normedRange.serialize(document, '.regbrain-annotation')
      if (serializedRange.start.includes(annotation.regulationContainerPath) && serializedRange.end.includes(annotation.regulationContainerPath)) {
        annotation.serializedRange = serializedRange
        annotation.normedRange = normedRange
      } else {
        range = null
        normedRange = null
        serializedRange = null
        selectedRange.detach()
        return
      }
      annotation.text = selectedRange.toString()
      // set serialised range relative to the regulations ID
      let serializedRangeRelative = Object.create(serializedRange)
      serializedRangeRelative.start = serializedRange.start.replace(annotation.regulationContainerPath, '')
      serializedRangeRelative.startOffset = serializedRange.startOffset
      serializedRangeRelative.end = serializedRange.end.replace(annotation.regulationContainerPath, '')
      serializedRangeRelative.endOffset = serializedRange.endOffset
      annotation.serializedRangeRelative = serializedRangeRelative
      this.annotation = annotation
      this.form.range = JSON.stringify(serializedRangeRelative)
      this.form.text = selectedRange.toString()
      this.obligationSidebar = true
      this.statusCreating = true
    },
    annotationDelete: async function (id) {
      try {
        const annotations = this.annotations
        await this.$Amplify.API.del('cosmos', `/standard/obligation/${id}`)
        _.each(this.annotations, annotation => {
          if (annotation.id === id) {
            $(annotation.highlights).toggleClass('regbrain-annotation', false)
            $(annotation.highlights).toggleClass('regbrain-annotation-focused', false)
          }
        })
        _.pullAllBy(annotations, [{ 'id': id }], 'id')
        this.annotations = []
        this.annotations = annotations
      } catch (e) {
        this.$logger.warn('delete error', e)
      }
    },
    annotationEditStart: function (id) {
      const annotation = _.find(this.annotations, ['id', id])
      const fields = ['date_effective', 'text', 'type_id', 'citation', 'name', 'description', 'context', 'standard', 'lagging', 'market_practice', 'topic', 'recordkeeping', 'internal_reporting', 'external_reporting', 'client_communication']
      const form = {}
      _.each(fields, field => {
        form[field] = annotation[field]
      })
      this.form = form
      this.annotation = annotation
      this.statusEditing = true
      this.obligationSidebar = true
    },
    annotationEditSave: async function () {
      try {
        const annotation = this.annotation
        const annotations = this.annotations
        this.form.updated_by = this.user.username
        this.form.updated_at = moment().format()
        const newAnnotation = { ...annotation, ...this.form }
        if (this.form.type_id) {
          newAnnotation.obligationtype = {
            id: this.form.type_id,
            name: _.find(this.typeOptions, ['value', this.form.type_id]).text
          }
        } else {
          newAnnotation.obligationtype = null
        }
        const params = {
          body: this.form
        }
        await this.$Amplify.API.put('cosmos', `/obligation/id/${annotation.id}`, params)
        this.annotation = newAnnotation
        _.pullAllBy(annotations, [{ 'id': this.annotation.id }], 'id')
        annotations.push(newAnnotation)
        this.annotations = []
        this.annotations = annotations
        this.annotationFormReset()
        this.annotationReset()
        this.obligationSidebar = false
        this.statusEditing = false
        const paramsLog = {
          body: {
            obligation_id: annotation.id,
            regulation_id: this.regulation.id,
            action: 'edited content',
            username: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/obligationslog', paramsLog)
      } catch (e) {
        this.$logger.warn('saving error', e)
      }
    },
    annotationFocus: function (id) {
      _.each(this.annotations, annotation => {
        $(annotation.highlights).toggleClass('regbrain-annotation', false)
      })
      const annotation = _.find(this.annotations, ['id', id])
      const scrollY = annotation.scrollY
      document.getElementById('regulationContainer').scroll(0, scrollY - 300)
      $(annotation.highlights).toggleClass('regbrain-annotation-focused')
      this.annotationFocused = annotation
    },
    annotationFocusCancel: function () {
      $(this.annotationFocused.highlights).toggleClass('regbrain-annotation-focused', false)
      if (this.highlightingOn) {
        _.each(this.annotations, annotation => {
          $(annotation.highlights).toggleClass('regbrain-annotation')
        })
      }
    },
    annotationFormReset: function () {
      this.form = {
        citation: '',
        context: '',
        created_by: '',
        date_effective: null,
        description: '',
        lagging: '',
        market_practice: '',
        name: '',
        range: '',
        regulation_id: '',
        standard: '',
        status_id: '',
        status_created_by: '',
        text: '',
        topic: '',
        type_id: null,
        updated_by: ''
      }
    },
    annotationHighlight: function (id) {
      const regContainer = document.getElementById('regulationContainer')
      const regContainerPath = xpathRange.pathFromNode(regContainer)
      _.each(this.annotations, annotation => {
        if (annotation.id === id) {
          const rangeRelative = JSON.parse(annotation.range)
          const rangeAbsolute = {
            start: regContainerPath + rangeRelative.start,
            startOffset: rangeRelative.startOffset,
            end: regContainerPath + rangeRelative.end,
            endOffset: rangeRelative.endOffset
          }
          const range = xpathRange.sniff(rangeAbsolute)
          const normedRange = range.normalize(document)
          const highlights = highlighter.highlightRange(normedRange)
          annotation.highlights = highlights
          annotation.scrollY = $(highlights[0]).offset().top + regContainer.scrollTop
        }
      })
    },
    annotationReset: function () {
      this.annotation = {
        serializedRangeRelative: '',
        text: ''
      }
    },
    annotationReselect: function () {
      // switch on highlighting to get correct ranges
      if (!this.highlightingOn) {
        _.each(this.annotations, annotation => {
          $(annotation.highlights).toggleClass('regbrain-annotation', true)
          $(annotation.highlights).toggleClass('regbrain-annotation-focused', true)
        })
      }
      let annotation = {}
      let selectedRange = xpathRange.getSelectedRange(document)
      // check if the selection is not empty
      if (selectedRange == null) {
        return
      }
      // check if the selection is in the regulation container range
      annotation.regulationContainerPath = xpathRange.pathFromNode(document.getElementById('regulationContainer'))
      let range = xpathRange.sniff(selectedRange)
      let normedRange = range.normalize(document)
      let serializedRange = normedRange.serialize(document, '.regbrain-annotation')
      if (serializedRange.start.includes(annotation.regulationContainerPath) && serializedRange.end.includes(annotation.regulationContainerPath)) {
        annotation.serializedRange = serializedRange
        annotation.normedRange = normedRange
      } else {
        range = null
        normedRange = null
        serializedRange = null
        selectedRange.detach()
        return
      }
      annotation.text = selectedRange.toString()
      // set serialised range relative to the regulations ID
      let serializedRangeRelative = Object.create(serializedRange)
      serializedRangeRelative.start = serializedRange.start.replace(annotation.regulationContainerPath, '')
      serializedRangeRelative.startOffset = serializedRange.startOffset
      serializedRangeRelative.end = serializedRange.end.replace(annotation.regulationContainerPath, '')
      serializedRangeRelative.endOffset = serializedRange.endOffset
      annotation.serializedRangeRelative = serializedRangeRelative
      this.annotation.regulationContainerPath = annotation.regulationContainerPath
      this.annotation.serializedRange = annotation.serializedRange
      this.annotation.normedRange = annotation.normedRange
      this.annotation.text = annotation.text
      this.annotation.serializedRangeRelative = annotation.serializedRangeRelative
      this.form.range = JSON.stringify(serializedRangeRelative)
      this.form.text = selectedRange.toString()
      if (this.annotation.id) {
        _.each(this.annotations, annotation => {
          if (annotation.id === this.annotation.id) {
            $(annotation.highlights).toggleClass('regbrain-annotation', false)
            $(annotation.highlights).toggleClass('regbrain-annotation-focused', false)
          }
        })
        const regContainer = document.getElementById('regulationContainer')
        const regContainerPath = xpathRange.pathFromNode(regContainer)
        const rangeRelative = serializedRangeRelative
        const rangeAbsolute = {
          start: regContainerPath + rangeRelative.start,
          startOffset: rangeRelative.startOffset,
          end: regContainerPath + rangeRelative.end,
          endOffset: rangeRelative.endOffset
        }
        const range = xpathRange.sniff(rangeAbsolute)
        const normedRange = range.normalize(document)
        const highlights = highlighter.highlightRange(normedRange)
        this.annotation.highlights = highlights
        this.annotation.scrollY = $(highlights[0]).offset().top + regContainer.scrollTop
      }
      // switch off highlighting if that was user's preference
      if (!this.highlightingOn) {
        _.each(this.annotations, annotation => {
          $(annotation.highlights).toggleClass('regbrain-annotation', false)
          $(annotation.highlights).toggleClass('regbrain-annotation-focused', false)
        })
      }
    },
    annotationSave: async function () {
      try {
        const annotation = this.annotation
        this.form.regulation_id = this.$route.params.id
        this.form.status_id = 1
        this.form.status_created_by = this.user.username
        this.form.created_by = this.user.username
        this.form.updated_by = this.user.username
        const newAnnotation = { ...annotation, ...this.form }
        const params = {
          body: this.form
        }
        const result = await this.$Amplify.API.put('cosmos', '/obligation/new', params)
        newAnnotation.range = this.form.range
        newAnnotation.id = result.id
        if (this.form.type_id) {
          newAnnotation.obligationtype = {
            id: this.form.type_id,
            name: _.find(this.typeOptions, ['value', this.form.type_id]).text
          }
          if (newAnnotation.obligationtype.name === 'entity') {
            this.entities.push(newAnnotation)
          }
        }
        newAnnotation.entities = []
        newAnnotation.areas = []
        newAnnotation.requirements = []
        newAnnotation.riskcomponents = []
        newAnnotation.active = []
        newAnnotation.passive = []
        this.annotation = newAnnotation
        this.annotations.push(this.annotation)
        this.annotationHighlight(this.annotation.id)
        this.annotationFormReset()
        this.annotationReset()
        this.obligationSidebar = false
        this.statusCreating = false
        const paramsLog = {
          body: {
            obligation_id: result.id,
            regulation_id: this.regulation.id,
            action: 'created new record',
            username: this.user.username
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/obligationslog', paramsLog)
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    annotationsHighlight: function () {
      document.getElementById('regulationContainer').scroll(0, 0)
      const regContainerPath = xpathRange.pathFromNode(document.getElementById('regulationContainer'))
      _.each(this.annotations, annotation => {
        try {
          const rangeRelative = JSON.parse(annotation.range)
          const rangeAbsolute = {
            start: regContainerPath + rangeRelative.start,
            startOffset: rangeRelative.startOffset,
            end: regContainerPath + rangeRelative.end,
            endOffset: rangeRelative.endOffset
          }
          const range = xpathRange.sniff(rangeAbsolute)
          const normedRange = range.normalize(document)
          const highlights = highlighter.highlightRange(normedRange)
          annotation.highlights = highlights
          annotation.scrollY = $(highlights[0]).offset().top
        } catch (e) {
          console.log('annotationsHighlight error')
          console.log(e)
        }
      })
    },
    download: function () {
      this.$logger.debug('loading started')
      const data = []
      const fields = [
        { field: 'regulation_id', name: 'regulation id' },
        { field: 'regulation_name_short', name: 'regulation name short' },
        { field: 'regulation_country_code', name: 'regulation country code' },
        { field: 'regulation_topics', name: 'regulation topics' },
        { field: 'regulation_tags', name: 'regulation tags' },
        { field: 'regulation_date_in_force', name: 'regulation date in force' },
        { field: 'regulation_date_last_amended', name: 'regulation date last amended' },
        { field: 'regulator', name: 'regulator' },
        { field: 'id', name: 'obligation id' },
        { field: 'scrollY', name: 'ordering in text' },
        { field: 'obligationtype', name: 'type' },
        { field: 'citation', name: 'citation' },
        { field: 'text', name: 'text' },
        { field: 'name', name: 'name' },
        { field: 'date_effective', name: 'effective date' },
        { field: 'description', name: 'description' },
        { field: 'context', name: 'context' },
        { field: 'standard', name: 'minimum standard' },
        { field: 'lagging', name: 'lagging market practice' },
        { field: 'market_practice', name: 'leading market practice' },
        { field: 'topic', name: 'topic' },
        { field: 'entities', name: 'entities' },
        { field: 'areas', name: 'functions' },
        { field: 'requirements', name: 'requirements' },
        { field: 'status_id', name: 'status id' },
        { field: 'status_name', name: 'status name' },
        { field: 'status_created_at', name: 'status created at' },
        { field: 'status_created_by', name: 'status created by' },
        { field: 'created_at', name: 'created at' },
        { field: 'created_by', name: 'created by' },
        { field: 'updated_at', name: 'updated at' },
        { field: 'updated_by', name: 'updated by' }
      ]
      const headings = _.map(fields, field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      _.each(this.annotations, annotation => {
        const row = _.map(fields, field => {
          if (field.field === 'regulation_name_short') {
            return { value: this.regulation.name_short, type: 'string' }
          }
          if (field.field === 'regulation_country_code') {
            return { value: this.regulation.country_code, type: 'string' }
          }
          if (field.field === 'regulation_topics') {
            return { value: this.regulation.regtopics.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulation_tags') {
            return { value: this.regulation.tags.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulation_date_in_force') {
            return { value: moment(this.regulation.date_in_force).format('YYYY-MM-DD'), type: 'string' }
          }
          if (field.field === 'regulation_date_last_amended') {
            return { value: moment(this.regulation.date_last_amended).format('YYYY-MM-DD'), type: 'string' }
          }
          if (field.field === 'regulator') {
            return { value: this.regulation.regulator.name, type: 'string' }
          }
          if (field.field === 'text') {
            return { value: annotation[field.field].replace(/\s\s+/g, ' '), type: 'string' }
          }
          if (field.field === 'status_name') {
            return { value: workflow.find(x => x.id === annotation.status_id).name, type: 'string' }
          }
          if (field.field === 'obligationtype') {
            if (annotation[field.field]) {
              return { value: annotation[field.field].name, type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'entities') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'areas') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'requirements') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          return { value: annotation[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'annotations', sheet: { data: data } }
      zipcelx(config)
    },
    importObligations: async function (regulationId, regulationGraphNode) {
      this.$stat.log({ page: 'workspace', action: 'import obligations' })
      this.editAlwaysOn = false
      this.annotationShowText = true
      this.regulationGraphNode = regulationGraphNode
      this.allowImport = true
      this.warning = "Showing obligations from " + regulationGraphNode.regulation.name_short
      this.showReload = true
      this.annotationReset()
      this.annotationFormReset()
      try {
        const response = await this.$Amplify.API.get('cosmos', `/obligations/regulation/${regulationId}`)
        this.annotations = response
      } catch (e) {
        this.$logger.warn('getting reulation error', e)
      }
    },
    logsShow: async function () {
      try {
        this.logs = await this.$Amplify.API.get('cosmos', `/obligations/regulation/${this.$route.params.id}/logs`)
        this.logsModal = true
      } catch (e) {
        this.$logger.warn('saving error', e)
      }
    },
    onRenderCompleted: function () {
      this.annotationsHighlight()
      this.ready = true
      this.loading = false
    },
    removeDeleted: async function () {
      try {
        await this.$Amplify.API.del('cosmos', '/obligations/deleted')
        const annotations = this.annotations
        _.each(this.annotations, annotation => {
          if (parseInt(annotation.status_id) === -1) {
            $(annotation.highlights).toggleClass('regbrain-annotation', false)
            $(annotation.highlights).toggleClass('regbrain-annotation-focused', false)
          }
        })
        _.pullAllBy(annotations, [{ 'status_id': -1 }, { 'status_id': '-1' }], 'status_id')
        this.annotations = []
        this.annotations = annotations
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    resetImport: function () {
      this.editAlwaysOn = true
      this.annotationShowText = false
      this.regulationGraphNode = null
      this.allowImport = false
      this.showReload = false
      this.warning = null
    }
  },
  watch: {
    '$route.params.id': 'firstLoad',
    highlightingOn: function () {
      _.each(this.annotations, annotation => {
        $(annotation.highlights).toggleClass('regbrain-annotation')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.annotations-sidebar {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.regulation-sidebar {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 15px;
}
.fab {
  position: fixed;
  top: 200px;
  left: 20%;
  z-index: 990;
}
</style>
